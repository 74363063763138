import React from 'react';

const QrSection = (props) => {
    const { id, gutters, children } = props;

    return (
        <section id={id} className={`${gutters ? "qr-section-gutters" : ""}`}>
            {children}
        </section>
    )
}

export default QrSection;