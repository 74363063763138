import * as React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useIntl } from "gatsby-plugin-intl";

import { QrSEO } from "../components/QrHead";
import QrNavbar from "../components/QrNavbar";
import QrFooter from "../components/QrFooter";
import QrSection from "../components/QrSection";

const NotFoundPage = () => {
  const intl = useIntl();

  return (
    <div id="qr-wrap">
      <QrSEO title={`Qranio - ${intl.formatMessage({ id: "ERROR.PAGE_NOT_FOUND_TEXT" })}`} pageName="404" pageClass="NotFoundPage" />
      <QrNavbar scrolled />

      <div id="page-not-found" className="content-navbar-fixed">
        <QrSection id="qr-page-not-found" gutters={true}>
          <Container>
            <Row>
              <Col xs={12} className="text-center">
                <h1>404</h1>
                <h4>{intl.formatMessage({ id: "ERROR.PAGE_NOT_FOUND_TEXT" })}</h4>
                <Button href="/" variant="primary">{intl.formatMessage({ id: "BUTTON.GO_HOME" })}</Button>
              </Col>
            </Row>
          </Container>
        </QrSection>
      </div>

      <QrFooter />
    </div>
  )
}

export default NotFoundPage
