import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl, Link } from "gatsby-plugin-intl";

import QrImage from './QrImage';
import Logo from '../assets/images/img_logo_single_inverse.svg';

const QrFooter = (props) => {
    const intl = useIntl();
    let fullYear = new Date().getFullYear();

    return (
        <footer id="qr-footer">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="list-social">
                            <a href="https://www.instagram.com/qranio/" target="_blank" className="list-social-item __instagram"></a>
                            <a href="https://www.facebook.com/qranio/" target="_blank" className="list-social-item __facebook"></a>
                            <a href="https://www.linkedin.com/company/qranio-com---making-learning-fun" target="_blank" className="list-social-item __linkedin"></a>
                            <a href="https://twitter.com/qranio" target="_blank" className="list-social-item __twitter"></a>
                        </div>
                    </Col>
                    <Col xs={12} className="d-flex align-items-center justify-content-center mt-4">
                        <QrImage src={Logo} />
                        <p>
                            © {fullYear}
                        </p>
                    </Col>
                    <Col xs={12} className="list-link">
                        <Link to="/terms-of-use">{intl.formatMessage({ id: "FOOTER.TERMS_OF_USE" })}</Link> |
                        <Link to="/privacy-policy">{intl.formatMessage({ id: "FOOTER.PRIVACY_POLICY" })}</Link>
                    </Col>
                    {/* <Col xs={12} className="d-flex align-items-center justify-content-center mt-3">
                        <div id="armored_website">
                            <param id="aw_preload" value="true" />
                            <param id="aw_use_cdn" value="true" />
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </footer>
    )
}

export default QrFooter;